import actions from '@/store/modules/register/register.actions';

// const STATES = {
// };

const state = {

};

const getters = {

};

const mutations = {

};

export default {
  state,
  getters,
  actions,
  mutations
};
