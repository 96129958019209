import { CONSTANTS as PROJECT_CONSTANTS, } from '@/store/modules/project/project.constants';

export default {
  [PROJECT_CONSTANTS.MUTATIONS.PROJECTS_SUCCESS](state, payload) {
    state[PROJECT_CONSTANTS.STATE.ACTIVE_WORKSPACE_PROJECTS] = payload;
  },
  [PROJECT_CONSTANTS.MUTATIONS.PROJECTS_RESET](state) {
    state[PROJECT_CONSTANTS.STATE.ACTIVE_WORKSPACE_PROJECTS] = [];
  },
};
