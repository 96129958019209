import { CONSTANTS as PROJECT_CONSTANTS, } from '@/store/modules/project/project.constants';
import { CONSTANTS as WORKSPACE_CONSTANTS, } from '@/store/modules/workspace/workspace.constants';
import ProjectsApi from '@/api/projects.api';

export default {
  [PROJECT_CONSTANTS.ACTIONS.PROJECT_CREATE](context, { projectDTO }) {
    return new Promise((resolve, reject) => {
      ProjectsApi.create({ projectDTO })
        .then(() => {
          context.dispatch(WORKSPACE_CONSTANTS.ACTIONS.WORKSPACE_PROJECTS_REQUEST)
            .catch(error => reject(error));
          resolve();
        })
        .catch(error => reject(error));
    });
  },

  [PROJECT_CONSTANTS.ACTIONS.PROJECT_UPDATE](context, data) {
    return new Promise((resolve, reject) => {
      const { _id, ...projectDTO } = data;
      ProjectsApi.update(_id, projectDTO)
        .then(() => {
          context.dispatch(WORKSPACE_CONSTANTS.ACTIONS.WORKSPACE_PROJECTS_REQUEST)
            .catch(error => reject(error));
          resolve();
        })
        .catch(error => reject(error));
    });
  },
};
