import Api from './api';

const resourceBaseUrl = 'inboxes';

class InboxesApi extends Api {

}

InboxesApi.resourceBaseUrl = resourceBaseUrl;

export default InboxesApi;
