import { CONSTANTS as BOARD_CONSTANTS } from '@/store/modules/board/board.constants';

export default {
  [BOARD_CONSTANTS.MUTATIONS.BOARD_SUCCESS](state, { boardDTO }) {
    state[BOARD_CONSTANTS.STATE.BOARD] = boardDTO;
  },
  [BOARD_CONSTANTS.MUTATIONS.BOARD_RESET](state) {
    state[BOARD_CONSTANTS.STATE.BOARD] = null;
  },
};
