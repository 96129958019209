import { CONSTANTS as USER_CONSTANTS } from '@/store/modules/user/user.constants';
import { CONSTANTS as INBOX_CONSTANTS } from '@/store/modules/inbox/inbox.constants';
import { CONSTANTS as WORKSPACE_CONSTANTS, } from '@/store/modules/workspace/workspace.constants';
import UsersApi from '@/api/users.api';

export default {
  [USER_CONSTANTS.ACTIONS.USERS_REQUEST]({ commit }) {
    return new Promise((resolve, reject) => {
      UsersApi.get()
        .then(({ data }) => {
          const { usersDTO } = data;
          commit(USER_CONSTANTS.MUTATIONS.USERS_SUCCESS, usersDTO);
          resolve();
        })
        .catch(error => reject(error));
    });
  },

  [USER_CONSTANTS.ACTIONS.USER_INBOXES_REQUEST]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      UsersApi.get(`${getters[USER_CONSTANTS.GETTERS.CURRENT_USER].email}/inboxes`)
        .then(({ data }) => {
          const { inboxDTO } = data;
          commit(INBOX_CONSTANTS.MUTATIONS.INBOXES_SUCCESS, inboxDTO);
          resolve();
        })
        .catch(error => reject(error));
    });
  },


  [USER_CONSTANTS.ACTIONS.CURRENT_USER_WORKSPACES_REQUEST]({ getters, dispatch }) {
    return new Promise((resolve, reject) => {
      // https://docs.microsoft.com/en-us/azure/architecture/best-practices/api-design
      UsersApi.get(`${getters[USER_CONSTANTS.GETTERS.CURRENT_USER]._id}/workspaces`)
        .then(({ data }) => {
          const { workspacesDTO } = data;
          dispatch(WORKSPACE_CONSTANTS.ACTIONS.WORKSPACES_SUCCESS, { workspacesDTO });
          resolve();
        })
        .catch(error => reject(error));
    });
  },
};
