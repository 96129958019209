import { CONSTANTS as WORKSPACE_CONSTANTS, } from '@/store/modules/workspace/workspace.constants';

export default {
  [WORKSPACE_CONSTANTS.MUTATIONS.WORKSPACES_SUCCESS](state, { workspacesDTO }) {
    state[WORKSPACE_CONSTANTS.STATE.WORKSPACES] = workspacesDTO;
  },
  [WORKSPACE_CONSTANTS.MUTATIONS.WORKSPACES_RESET](state) {
    state[WORKSPACE_CONSTANTS.STATE.WORKSPACES] = [];
    state[WORKSPACE_CONSTANTS.STATE.ACTIVE_WORKSPACE] = null;
  },
  [WORKSPACE_CONSTANTS.MUTATIONS.SET_ACTIVE_WORKSPACE](state, workspace) {
    state[WORKSPACE_CONSTANTS.STATE.ACTIVE_WORKSPACE] = workspace;
  }
};
