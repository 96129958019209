import { CONSTANTS as WORKSPACE_CONSTANTS, } from '@/store/modules/workspace/workspace.constants';
import { CONSTANTS as STORY_CONSTANTS } from '@/store/modules/story/story.constants';
import { CONSTANTS as COMMENT_CONSTANTS } from '@/store/modules/comment/comment.constants';
import StoriesApi from '@/api/stories.api';

export default {
  [STORY_CONSTANTS.ACTIONS.STORY_CREATE](context, credentials) {
    return new Promise((resolve, reject) => {
      StoriesApi.create(credentials)
        .then(() => {
          resolve();
        })
        .catch(error => reject(error));
    });
  },

  [STORY_CONSTANTS.ACTIONS.STORY_REQUEST](context, _id) {
    return new Promise((resolve, reject) => {
      StoriesApi.get(_id)
        .then(({ data }) => {
          context.commit(STORY_CONSTANTS.MUTATIONS.STORY_SUCCESS, data);
          resolve();
        })
        .catch(error => reject(error));
    });
  },

  [STORY_CONSTANTS.ACTIONS.STORY_UPDATE](context, storyData) {
    return new Promise((resolve, reject) => {
      const { _id, ...StoryDataWithoutID } = storyData;
      StoriesApi.update(_id, { StoryDataWithoutID })
        .then(() => {
          context.dispatch(WORKSPACE_CONSTANTS.ACTIONS.WORKSPACE_STORIES_REQUEST)
            .catch(error => reject(error));
          resolve();
        })
        .catch(error => reject(error));
    });
  },

  [STORY_CONSTANTS.ACTIONS.STORY_COMMENTS_REQUEST]({ commit }, storyId) {
    return new Promise((resolve, reject) => {
      StoriesApi.get(`${storyId}/comments`)
        .then(({ data }) => {
          commit(COMMENT_CONSTANTS.MUTATIONS.STORY_COMMENTS_SUCCESS, data);
          resolve();
        })
        .catch(error => reject(error));
    });
  },
};
