import { CONSTANTS as COMMENT_CONSTANTS } from '@/store/modules/comment/comment.constants';
import getters from '@/store/modules/comment/comment.getters';
import actions from '@/store/modules/comment/comment.actions';
import mutations from '@/store/modules/comment/comment.mutations';

const state = {
  [COMMENT_CONSTANTS.STATE.CURRENT_COMMENTS]: [],
};

export default {
  state,
  getters,
  actions,
  mutations
};
