const STATE = {
  CURRENT_USER_STORIES: 'currentUserStories'
};

export const GETTERS = {
  STORY_TYPES: 'STORY_TYPES',
  STORY_TYPES_WITH_ICON: 'STORY_TYPES_WITH_ICON',
  STORY_PRIORITIES: 'STORY_PRIORITIES',
  STORY_PRIORITIES_WITH_ICON: 'STORY_PRIORITIES_WITH_ICON',
  USER_STORIES: 'USER_STORIES',
  USER_STORY: 'USER_STORY',
};

export const ACTIONS = {
  STORY_REQUEST: 'STORY_REQUEST',
  STORY_CREATE: 'STORY_CREATE',
  STORY_UPDATE: 'STORY_UPDATE',

  STORY_COMMENTS_REQUEST: 'STORY_COMMENTS_REQUEST',
};

export const MUTATIONS = {
  STORIES_SUCCESS: 'STORIES_SUCCESS',
  STORY_SUCCESS: 'STORY_SUCCESS',
  STORIES_RESET: 'STORIES_RESET',
};

export const CONSTANTS = {
  STATE,
  GETTERS,
  ACTIONS,
  MUTATIONS,
};
