import { CONSTANTS as BOARD_CONSTANTS } from '@/store/modules/board/board.constants';

export default {
  [BOARD_CONSTANTS.GETTERS.BOARD](state) {
    return state[BOARD_CONSTANTS.STATE.BOARD];
  },
  [BOARD_CONSTANTS.GETTERS.BOARD_STATUSES](state) {
    return state[BOARD_CONSTANTS.STATE.BOARD].statuses;
  }
};
