<style lang="scss">
.homepage-header {
  padding: 40px 20px 30px 20px;
}
.homepage-banner {
  padding-bottom: 30px;
}
.homepage-first-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 20px;
}
.homepage-first-section-img {
  margin: 20px;
}
.first-section-background-img {
background: #F9F9F9; box-shadow: 0 0 8px 4px rgba(0,0,0,0.05);
}
.homepage-second-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.homepage-second-section-img {
  padding-top: 20px;
}
.homepage-tabs {
  padding-top: 20px;
  padding-bottom: 30px;
}
.third-section-background-img {
  background: #112836;
}
.homepage-third-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 160px 0;
  text-align: center;
  img {
    width: 94px;
    padding-bottom: 20px;
  }
  h2 {
    padding-bottom: 30px;
  }
}
.homepage-footer {
  background: #112836;
  padding: 30px 0;
  text-align: center;
}
</style>
<template>
  <div class="home">
    <div class="columns">
      <div class="column">
        <h1 class="homepage-header has-text-centered is-size-1 has-text-weight-bold m3">
          A platfrom built for everyone from anywhere
        </h1>
        <h3 class="homepage-banner has-text-centered is-size-5">
          Twork is a project management platform catering to make your ambition a reality.
        </h3>
      </div>
    </div>
    <div class="columns first-section-background-img">
      <div class="column">
        <div class="homepage-first-section">
          <div>
            Want efficient collaboration with anyone across the world?
            Twork is a flexible and easy to use platform for anyone
            who wants to team up and make their dreams a reality.
          </div>
          <div>
            <router-link
              class="button is-primary mt-3"
              to="/signup">
              <span>Get it free</span>
            </router-link>
            <router-link
              class="button is-primary is-outlined mt-3"
              :to="{name: 'login', params: { isDemo: true }}">
              <span>Demo</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="column">
        <img class="homepage-first-section-img"
        src="../assets/twork_kanban.png" alt="Twork Kanban">
      </div>
    </div>
    <section class="homepage-tabs">
      <b-tabs type="" v-model="activeTab" position="is-centered">
        <b-tab-item label="Driven" icon="rocket">
          <div class="columns">
            <div class="column">
              <img class="homepage-second-section-img"
              src="../assets/driven.png" alt="Driven">
            </div>
            <div class="column">
              <div class="homepage-second-section">
                Create projects. Create and assign stories to a project.
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Board" icon="columns">
          <div class="columns">
            <div class="column">
              <img class="homepage-second-section-img"
              src="../assets/kanban.png" alt="Kanban">
            </div>
            <div class="column">
              <div class="homepage-second-section">
                Transition stories from In Progress up to Completed all seemless
                within the Kanban board.
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Workspace" icon="industry">
          <div class="columns">
            <div class="column">
              <img class="homepage-second-section-img"
              src="../assets/workspace.png" alt="Workspace">
            </div>
            <div class="column">
              <div class="homepage-second-section">
                Send invites to Twork users to your workspace and start collaborating!
                Switch between your workspace and the ones you are invited to.
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Teamwork" icon="users">
          <div class="columns">
            <div class="column">
              <img class="homepage-second-section-img"
              src="../assets/teamwork.png" alt="Teamwork">
            </div>
            <div class="column">
              <div class="homepage-second-section">
                Work together on a story and start communicating your thoughts and ideas.
                Assign a story to a teamate.
              </div>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </section>
    <div class="third-section-background-img">
      <div class="column">
        <div class="homepage-third-section">
          <div>
            <img
              src="../assets/twork.png"
              alt="TWORK company logo"
            >
          </div>
          <h2 class="has-text-white is-size-2">
            Try the #1 platform for project management
          </h2>
          <div>
            <router-link
              class="button is-primary is-outlined is-inverted is-medium mt-3"
              to="/signup">
              <span>Get Started</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="homepage-footer has-text-white">
        <div>
          © 2023 Twork by Qian Pan
        </div>
        <div>
          Terms & Privacy
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'HomeView',
  data() {
    return {
      activeTab: 0,
    };
  },
};
</script>
