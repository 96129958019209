import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import axios from 'axios';
import getters from './getters';
import auth from './modules/auth/auth.store';
import register from './modules/register/register.store';
import user from './modules/user/user.store';
import workspace from './modules/workspace/workspace.store';
import project from './modules/project/project.store';
import story from './modules/story/story.store';
import comment from './modules/comment/comment.store';
import inbox from './modules/inbox/inbox.store';
import board from './modules/board/board.store';

if (process.env.NODE_ENV) axios.defaults.baseURL = 'http://localhost:3000';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {

  },
  getters: {
    ...getters
  },
  actions: {

  },
  mutations: {

  },
  modules: {
    auth,
    register,
    user,
    project,
    story,
    inbox,
    workspace,
    comment,
    // I am not sure why appending a comma causes unable to resolve path to module for "boards"
    // To get this error "comments," must be before "boards,", and "boards" must be last item here.
    // board,
    board,
  },
  /* https://stackoverflow.com/questions/43027499/vuex-state-on-page-refresh */
  /* https://stackoverflow.com/questions/55319006/making-only-one-module-persistent-with-vuex-persistedstate */
  /* https://alligator.io/vuejs/vuex-persist-state/ */
  /* https://stackoverflow.com/questions/52591795/vuex-persisted-state-doesnt-remove-state-after-closing-tab */
  plugins: [createPersistedState()]
});
