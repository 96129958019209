import { asyncHandler } from '@/utils/utils';
import { CONSTANTS as REGISTER_CONSTANTS } from '@/store/modules/register/register.constants';
import UsersApi from '@/api/users.api';

// The trade-off is the action looses the context of 'this' that references the Store object
export default {
  [REGISTER_CONSTANTS.ACTIONS.REGISTER_REQUEST]: asyncHandler(
    async (context, credential) => UsersApi.create(credential)
  ),
};
