import { CONSTANTS as INBOX_CONSTANTS } from '@/store/modules/inbox/inbox.constants';

export default {
  [INBOX_CONSTANTS.MUTATIONS.INBOXES_SUCCESS](state, payload) {
    state[INBOX_CONSTANTS.STATE.CURRENT_USER_INBOXES] = payload;
  },
  [INBOX_CONSTANTS.MUTATIONS.INBOXES_RESET](state) {
    state[INBOX_CONSTANTS.STATE.CURRENT_USER_INBOXES] = [];
  },
};
