import { CONSTANTS as PROJECT_CONSTANTS, } from '@/store/modules/project/project.constants';

export default {
  [PROJECT_CONSTANTS.GETTERS.ACTIVE_WORKSPACE_PROJECTS](state) {
    return state[PROJECT_CONSTANTS.STATE.ACTIVE_WORKSPACE_PROJECTS];
  },
  [PROJECT_CONSTANTS.GETTERS.ACTIVE_WORKSPACE_PROJECT](state) {
    return id => state[PROJECT_CONSTANTS.STATE.ACTIVE_WORKSPACE_PROJECTS]
      .find(item => item._id === id);
  }
};
