import { CONSTANTS as STORY_CONSTANTS } from '@/store/modules/story/story.constants';
import getters from '@/store/modules/story/story.getters';
import actions from '@/store/modules/story/story.actions';
import mutations from '@/store/modules/story/story.mutations';

const state = {
  [STORY_CONSTANTS.STATE.CURRENT_USER_STORIES]: [],
};

export default {
  state,
  getters,
  actions,
  mutations
};
