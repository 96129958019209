import Api from './api';

const resourceBaseUrl = 'boards';

class BoardsApi extends Api {

}

BoardsApi.resourceBaseUrl = resourceBaseUrl;

export default BoardsApi;
