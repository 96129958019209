import Beufy from 'buefy';
import 'buefy/dist/buefy.css';

/* https://medium.com/front-end-weekly/how-to-use-fon-awesome-5-on-vuejs-project-ff0f28310821 */
import '@fortawesome/fontawesome-free/css/all.css';
// https://stackoverflow.com/questions/52484377/font-awesome-icons-in-buefy
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import Vue from 'vue';
import VeeValidate from 'vee-validate';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import VueJsonPretty from 'vue-json-pretty';
import Api from '@/api/api';
import App from './App.vue';
import router from './router';
import store from './store';

Api.init();

library.add(fas);

Vue.component('vue-fontawesome', FontAwesomeIcon);
Vue.use(Beufy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
  customIconPacks: {
    fas: {
      sizes: {
        default: 'lg',
        'is-small': '1x',
        'is-medium': '2x',
        'is-large': '3x'
      },
      iconPrefix: ''
    }
  }
});

Vue.use(VeeValidate, { events: 'blur' });

Vue.component('multiselect', Multiselect);

Vue.component('vue-json-pretty', VueJsonPretty);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
