const STATE = {
  CURRENT_USER: 'currentUser',
  USERS: 'users'
};

export const GETTERS = {
  CURRENT_USER: 'CURRENT_USER',
  ALL_USERS_PROFILE_EXCEPT_CURRENT_USER: 'ALL_USERS_PROFILE_EXCEPT_CURRENT_USER',
  USERS: 'USERS',
};

export const ACTIONS = {
  USERS_REQUEST: 'USERS_REQUEST',

  USER_INBOXES_REQUEST: 'USER_INBOXES_REQUEST',

  CURRENT_USER_WORKSPACES_REQUEST: 'CURRENT_USER_WORKSPACES_REQUEST',
};

export const MUTATIONS = {
  USER_SUCCESS: 'USER_SUCCESS',
  USER_RESET: 'USER_RESET',
  USERS_SUCCESS: 'USERS_SUCCESS',
};

export const CONSTANTS = {
  STATE,
  GETTERS,
  ACTIONS,
  MUTATIONS,
};
