const STATE = {
  BOARD: 'board'
};
export const GETTERS = {
  BOARD: 'BOARD',
  BOARD_STATUSES: 'BOARD_STATUSES'
};

export const ACTIONS = {
  BOARD_SUCCESS: 'BOARD_SUCCESS',
  BOARD_UPDATE: 'BOARD_UPDATE',
  BOARD_TRANSITION_STORY_STATUS: 'BOARD_TRANSITION_STORY_STATUS',
  BOARD_RESET: 'BOARD_RESET',
};

export const MUTATIONS = {
  BOARD_SUCCESS: 'BOARD_SUCCESS',
  BOARD_RESET: 'BOARD_RESET',
};

export const CONSTANTS = {
  STATE,
  GETTERS,
  ACTIONS,
  MUTATIONS,
};
