import Api from './api';

const resourceBaseUrl = 'comments';

class CommentsApi extends Api {

}

CommentsApi.resourceBaseUrl = resourceBaseUrl;

export default CommentsApi;
