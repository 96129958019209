<template>
  <div id="app">
    <b-navbar v-if="IS_AUTHENTICATED && ACTIVE_WORKSPACE" fixed-top class="is-light">
      <template #brand>
        <b-navbar-item tag="router-link" :to="`/${ACTIVE_WORKSPACE.title}/dashboard`">
          <img
            src="./assets/twork.png"
            alt="TWORK company logo"
          >
        </b-navbar-item>
      </template>
      <template v-if="ACTIVE_WORKSPACE">
        <template slot="start">
          <b-navbar-dropdown>
            <template slot="label">
              <b>{{ ACTIVE_WORKSPACE.title }}</b>
            </template>
            <b-navbar-item v-for="(workspace, index) in WORKSPACES"
              v-bind:key="index"
              :href="`/${workspace.title}/dashboard`">
              {{ workspace.title }}
            </b-navbar-item>
          </b-navbar-dropdown>

          <b-navbar-item tag="div">
            <b-dropdown
              aria-role="list"
              :mobile-modal="false"
              position="is-bottom-right"
              @hook:mounted="childMounted"
              >
              <b-button
                rounded
                class="is-light is-fullwidth"
                slot="trigger"
                :type="USER_INBOXES.length && 'is-danger'"
              >
              <div class="is-flex">
                <b-icon
                  pack="fas"
                  icon="bullhorn"
                  size="is-medium"
                  type="is-dark">
                </b-icon>
                <div class="ml-2">
                  Notifications
                  <span class="has-text-danger">
                    {{USER_INBOXES.length ? USER_INBOXES.length : '' }}
                  </span>
                </div>
              </div>
              </b-button>
              <b-dropdown-item aria-role="listitem">
                <InboxMenu />
              </b-dropdown-item>
            </b-dropdown>
          </b-navbar-item>
        </template>

        <template slot="end">
        </template>
      </template>
    </b-navbar>
    <nav v-if="!IS_AUTHENTICATED" class="navbar is-fixed-top"
         :class="{ 'is-dark': IS_AUTHENTICATED, 'scrolled': !view.atTopOfPage }"
         style="display:flex;align-items:stretch;"
         role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <router-link class="navbar-item" to="/">
          <img src="./assets/twork.png"
               alt="TWORK company logo"
          >
        </router-link>
      </div>
      <div v-if="!IS_AUTHENTICATED" class="buttons"
           style="flex-basis:100%;justify-content:flex-end;margin-right:12px;">
        <router-link class="button is-primary" to="/login" v-if="isSignupPage">
          <span>Log in</span>
        </router-link>
        <router-link class="button is-primary is-outlined" to="/signup" v-if="isLoginPage">
          <span>Sign up</span>
        </router-link>
      </div>
    </nav>
    <div class="sidebar-page">
      <section class="sidebar-layout">
        <b-sidebar
          v-if="IS_AUTHENTICATED && ACTIVE_WORKSPACE"
          position="static"
          :fullheight="true"
          mobile="reduce"
          :reduce="reduce"
          type="is-light"
          open
        >
        <div class="p-1">
            <b-menu class="is-custom-mobile">
              <b-menu-list>
                <b-menu-item
                  :label="!reduce ? 'Dashboard' : ''"
                  icon="tachometer-alt"
                  tag="router-link"
                  :to="`/${ACTIVE_WORKSPACE.title}/dashboard`"
                >
                </b-menu-item>
                <b-menu-item
                  :label="!reduce ? 'Stories' : ''"
                  icon="cube"
                  tag="router-link"
                  :to="`/${ACTIVE_WORKSPACE.title}/stories`"
                >
                </b-menu-item>
                <b-menu-item
                  :label="!reduce ? 'Projects' : ''"
                  icon="rocket"
                  tag="router-link"
                  :to="`/${ACTIVE_WORKSPACE.title}/projects`"
                >
                </b-menu-item>
                <b-menu-item
                  :label="!reduce ? 'Settings' : ''"
                  icon="cog"
                  tag="router-link"
                  :to="`/settings`"
                >
                </b-menu-item>
                <b-menu-item
                  :label="!reduce ? 'Logout' : ''"
                  icon="power-off"
                  tag="router-link"
                  :to="`/logout`"
                >
                </b-menu-item>
                <b-menu-item
                  :icon="reduce ? 'angle-double-right' : 'angle-double-left'"
                  class="has-text-centered"
                  @click="reduce = !reduce"
                >
                </b-menu-item>
                <!-- <b-menu-item
                  label="Logout"
                  icon="power-off"
                  tag="router-link"
                  :to="`/logout`"
                >
                </b-menu-item> -->
              </b-menu-list>
            </b-menu>
          </div>
        </b-sidebar>
        <!-- Using the vue if/else of <router-view> seems to be the cause of an
          'Uncaught (in promise) undefined...vue-router' error with these steps:
          1. login
          2. in the browser url, type localhost:8080/login (which gets redirected to '/')
          3. click logout. Notice this error message
          Note:
          1. It seems the next() function (file: client/src/router/index.js)
          that is nested in an else block
          that is nested in else if (to.matched.some(record => record.meta.requiresVisitor)) block,
          is called twice, hence probable contributor to the error.
          2. If passed a callback to this.$router.push('login')
          (file: src/views/Logout.vue) (e.g. this.$router.push('login'), () => {})
          slience the error. OR if chain a catch method,
          (e.g. this.$router.push('login').catch(() => {})
          can handle the error that is otherwise sent to the global router error handler.
          /* https://github.com/vuejs/vue-router/issues/2911 */
          /* https://github.com/vuejs/vue-router/issues/2873 */
          /* https://github.com/vuejs/vue-router/issues/2881 */
          OR if $route.push('home') that is basically not routes in:
          ```
          <div id="content" v-if="IS_AUTHENTICATED">
            <router-view/>
          </div>
          ```
          resolves the issue.
          2.Having a single <router-view> seems to resolve the problem.
            Having more than one <router-view> on the same level seems to cause the error.
          3. My undestanding tells me it is a combination of having more than one <router-view>
            on the same level and something about causing to push duplicate route
            that causes the uncaught promise error.
        -->
        <div id="content" ref="content" v-if="IS_AUTHENTICATED">
          <!-- https://stackoverflow.com/questions/52847979/what-is-router-view-key-route-fullpath -->
          <router-view :key="$route.fullPath"/>
        </div>
      </section>
        <router-view v-if="!IS_AUTHENTICATED"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CONSTANTS as WORKSPACE_CONSTANTS } from '@/store/modules/workspace/workspace.constants';
import { CONSTANTS as AUTH_CONSTANTS } from '@/store/modules/auth/auth.constants';
import { CONSTANTS as USER_CONSTANTS } from '@/store/modules/user/user.constants';
import { CONSTANTS as INBOX_CONSTANTS } from '@/store/modules/inbox/inbox.constants';
import InboxMenu from '@/components/containerComponents/InboxMenu.component.vue';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';

export default {
  data() {
    return {
      view: {
        atTopOfPage: true
      },
      reduce: false,
      isLoginPage: {
        type: Boolean,
        default: false
      },
      isSignupPage: {
        type: Boolean,
        default: false
      },
    };
  },
  computed: {
    ...mapGetters([
      AUTH_CONSTANTS.GETTERS.IS_AUTHENTICATED,
      USER_CONSTANTS.GETTERS.CURRENT_USER,
      WORKSPACE_CONSTANTS.GETTERS.ACTIVE_WORKSPACE,
      WORKSPACE_CONSTANTS.GETTERS.WORKSPACES,
      INBOX_CONSTANTS.GETTERS.USER_INBOXES,
    ]),
  },
  watch: {
    $route(to) {
      if (to.name === 'login') {
        this.isLoginPage = true;
        this.isSignupPage = false;
      } else if (to.name === 'signup') {
        this.isSignupPage = true;
        this.isLoginPage = false;
      } else {
        this.isSignupPage = true;
        this.isLoginPage = true;
      }
    }
  },
  beforeMount() {
    // https://chrisdermody.com/tailwind-css-static-navbar-with-shadow-on-scroll/
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 0) {
        if (this.view.atTopOfPage) this.view.atTopOfPage = false;
      } else if (!this.view.atTopOfPage) {
        this.view.atTopOfPage = true;
      }
    },
    childMounted() {
      this.$store.dispatch(USER_CONSTANTS.ACTIONS.USER_INBOXES_REQUEST)
        .catch(error => console.error(error));
    },
  },
  components: { InboxMenu },
};
</script>

<style lang="scss">
@import "assets/sass/main.scss";

// https://buefy.org/documentation/customization/
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: #8c67ef;
$primary-invert: findColorInvert($primary);
$twitter: #4099FF;
$twitter-invert: findColorInvert($twitter);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": ($success, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": ($danger, $danger-invert),
    "twitter": ($twitter, $twitter-invert)
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// https://agaric.gitlab.io/raw-notes/notes/2018-10-02-add-navbar-breakpoint-variable-to-bulma-css-framework/
// https://stackoverflow.com/questions/48809328/bulma-navbar-breakpoint
// https://github.com/jgthms/bulma/pull/2109
// https://stackoverflow.com/questions/52467626/bulma-show-uncollapsed-navbar-on-tablet
$navbar-breakpoint: $tablet;

$loading-background: rgba(255,255,255,1);

// https://bulma.io/documentation/components/dropdown/
$dropdown-item-active-color: black;
$dropdown-item-active-background-color: #e7e7e7;

@import "~bulma";
@import "~buefy/src/scss/buefy";
.menu-list a:hover {
    background-color: #dbdbdb;
}

html, body, #app {
  height: 100%;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  // padding-top: 52px;
}

@media screen and (max-width: 1023px) {
.navbar.is-fixed-top .navbar-menu, .navbar.is-fixed-top-touch .navbar-menu {
    -webkit-overflow-scrolling: touch;
    max-height: inherit;
    overflow: inherit;
  }
}

@media screen and (max-width: 1023px) {
  .navbar-menu {
    background-color: whitesmoke;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
  }
}

.sidebar-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding-top: 52px;
  .sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100%;

    .b-sidebar {
      .menu {
        .menu-list {
          @media screen and (max-width: 768px) {
            li a span + span {
              display: none;
            }
          }
        }
      }
    }
  }
}

#nav {
  text-align: center;
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

nav.scrolled {
  box-shadow: 0 2px 12px 0 rgba(36, 50, 66, 0.08);
}

#content {
  height: calc(100vh - 52px);
  width: 100%;
  -webkit-transition: 0.2s margin-left;
  transition: 0.2s margin-left;
  overflow: auto;
  > div {
    padding: 15px;
    width: 100%;
  }
}
</style>

<style lang="scss">
// vue-color 'Compact' colors
._4D4D4D {
  border-left-color: #4D4D4D !important;
}
._333333 {
  border-left-color: #333333 !important;
}
._000000 {
  border-left-color: #000000 !important;
}
._999999 {
  border-left-color: #999999 !important;
}
._808080 {
  border-left-color: #808080 !important;
}
._666666 {
  border-left-color: #666666 !important;
}
._FFFFFF {
  border-left-color: #FFFFFF !important;
}
._CCCCCC {
  border-left-color: #CCCCCC !important;
}
._B3B3B3 {
  border-left-color: #B3B3B3 !important;
}
._F44E3B {
  border-left-color: #F44E3B !important;
}
._D33115 {
  border-left-color: #D33115 !important;
}
._9F0500 {
  border-left-color: #9F0500 !important;
}
._FE9200 {
  border-left-color: #FE9200 !important;
}
._E27300 {
  border-left-color: #E27300 !important;
}
._C45100 {
  border-left-color: #C45100 !important;
}
._FCDC00 {
  border-left-color: #FCDC00 !important;
}
._FCC400 {
  border-left-color: #FCC400 !important;
}
._FB9E00 {
  border-left-color: #FB9E00 !important;
}
._DBDF00 {
  border-left-color: #DBDF00 !important;
}
._B0BC00 {
  border-left-color: #B0BC00 !important;
}
._808900 {
  border-left-color: #808900 !important;
}
._A4DD00 {
  border-left-color: #A4DD00 !important;
}
._68BC00 {
  border-left-color: #68BC00 !important;
}
._194D33 {
  border-left-color: #194D33 !important;
}
._009CE0 {
  border-left-color: #009CE0 !important;
}
._68CCCA {
  border-left-color: #68CCCA !important;
}
._16A5A5 {
  border-left-color: #16A5A5 !important;
}
._0C797D {
  border-left-color: #0C797D !important;
}
._73D8FF {
  border-left-color: #73D8FF !important;
}
._009CE0 {
  border-left-color: #009CE0 !important;
}
._0062B1 {
  border-left-color: #0062B1 !important;
}
._AEA1FF {
  border-left-color: #AEA1FF !important;
}
._7B64FF {
  border-left-color: #7B64FF !important;
}
._653294 {
  border-left-color: #653294 !important;
}
._FDA1FF {
  border-left-color: #FDA1FF !important;
}
._FA28FF {
  border-left-color: #FA28FF !important;
}
._AB149E {
  border-left-color: #AB149E !important;
}
</style>

<style lang="scss">
/* Styles overrides for Beufy */
body.has-navbar-fixed-top {
  padding-top: 0;
}

#app .navbar.is-fixed-top {
  z-index: 1000;
}

.modal {
  &.is-active {
    z-index: 1000
  }
}
</style>

<style lang="scss">
/* Styles overrides for Multiselect */
.multiselect {
  + input {
    height: 0px;
    width: 0px;
    border-color: transparent;
    position: absolute;
    bottom: 0;
    left: 50%;
    opacity: 0;
  }
  &__content-wrapper {
    > .multiselect__content {
      margin-left: 0;
      margin-top: 0;
    }
  }
}
</style>

<style lang="scss">
.vsm--link:hover > .vsm--icon {
  transition: all .2s ease-in-out;
  transform: scale(1.1);
}

/* Styles overrides for vue router-link and menu-list inside b-sidebar */
.b-sidebar {
  > .sidebar-content {
    > .p-1 {
      height: 100%;
      > .menu {
        height: 100%;
        > .menu-list {
          height: 100%;
          display: flex;
          flex-direction: column;
          li:last-child {
            // https://stackoverflow.com/questions/31000885/align-an-element-to-bottom-with-flexbox
            margin-top: auto;
          }
        }
      }
    }
  }

  .menu-list {
    > li a.is-active {
      background: none;
      color: #4a4a4a;
    }
  }
  .router-link-exact-active.router-link-active {
    background-color: #8c67ef;
    color: #fff;
  }
}
</style>
