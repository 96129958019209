import { asyncHandler } from '@/utils/utils';
import { CONSTANTS as USER_CONSTANTS } from '@/store/modules/user/user.constants';
import { CONSTANTS as WORKSPACE_CONSTANTS, } from '@/store/modules/workspace/workspace.constants';
import { CONSTANTS as BOARD_CONSTANTS } from '@/store/modules/board/board.constants';
import { CONSTANTS as PROJECT_CONSTANTS, } from '@/store/modules/project/project.constants';
import { CONSTANTS as STORY_CONSTANTS } from '@/store/modules/story/story.constants';
import WorkspacesApi from '@/api/workspaces.api';

export default {
  [WORKSPACE_CONSTANTS.ACTIONS.WORKSPACES_SUCCESS](context, { workspacesDTO }) {
    context.commit(WORKSPACE_CONSTANTS.MUTATIONS.WORKSPACES_SUCCESS, { workspacesDTO });
  },

  [WORKSPACE_CONSTANTS.ACTIONS.SET_ACTIVE_WORKSPACE](context, activeWorkspace) {
    context.commit(WORKSPACE_CONSTANTS.MUTATIONS.SET_ACTIVE_WORKSPACE, activeWorkspace);
  },

  [WORKSPACE_CONSTANTS.ACTIONS.WORKSPACE_ACCEPT](context, { inboxDTO }) {
    const newMemberId = context.getters[USER_CONSTANTS.GETTERS.CURRENT_USER]._id;

    return new Promise((resolve, reject) => {
      const { metadata } = inboxDTO;
      WorkspacesApi.create({
        workspaceDTO: { members: newMemberId, ...metadata.inviteUserToWorkspace }
      },
      'acceptUserToWorkspace')
        .then(() => {
          context
            .dispatch(USER_CONSTANTS.ACTIONS.CURRENT_USER_WORKSPACES_REQUEST)
            .catch(error => console.error(error));
          resolve();
        })
        .catch(error => reject(error));
    });
  },

  [WORKSPACE_CONSTANTS.ACTIONS.WORKSPACE_BOARD_REQUEST]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      WorkspacesApi.get(`${getters[WORKSPACE_CONSTANTS.GETTERS.ACTIVE_WORKSPACE]._id}/board`)
        .then(({ data }) => {
          const { boardDTO } = data;
          commit(BOARD_CONSTANTS.MUTATIONS.BOARD_SUCCESS, { boardDTO });
          resolve();
        })
        .catch(error => reject(error));
    });
  },

  [WORKSPACE_CONSTANTS.ACTIONS.WORKSPACE_PROJECTS_REQUEST]: asyncHandler(
    async ({ commit, getters }) => {
      const { data } = await WorkspacesApi
        .get(`${getters[WORKSPACE_CONSTANTS.GETTERS.ACTIVE_WORKSPACE]._id}/projects`);

      const { projectDTO } = data;
      commit(PROJECT_CONSTANTS.MUTATIONS.PROJECTS_SUCCESS, projectDTO);
    }
  ),

  [WORKSPACE_CONSTANTS.ACTIONS.WORKSPACE_STORIES_REQUEST]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      WorkspacesApi.get(`${getters[WORKSPACE_CONSTANTS.GETTERS.ACTIVE_WORKSPACE]._id}/stories`)
        .then(({ data }) => {
          commit(STORY_CONSTANTS.MUTATIONS.STORIES_SUCCESS, data);
          resolve();
        })
        .catch(error => reject(error));
    });
  },

};
