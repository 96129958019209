import { CONSTANTS as AUTH_CONSTANTS, } from '@/store/modules/auth/auth.constants';
import { CONSTANTS as USER_CONSTANTS } from '@/store/modules/user/user.constants';
import { CONSTANTS as WORKSPACE_CONSTANTS, } from '@/store/modules/workspace/workspace.constants';
import { CONSTANTS as BOARD_CONSTANTS } from '@/store/modules/board/board.constants';
import { CONSTANTS as INBOX_CONSTANTS } from '@/store/modules/inbox/inbox.constants';
import { CONSTANTS as PROJECT_CONSTANTS, } from '@/store/modules/project/project.constants';
import { CONSTANTS as STORY_CONSTANTS } from '@/store/modules/story/story.constants';
import { CONSTANTS as COMMENT_CONSTANTS } from '@/store/modules/comment/comment.constants';
import authenticateRequest from './auth.api';

export default {
  [AUTH_CONSTANTS.ACTIONS.AUTH_REQUEST](context, credentials) {
    return new Promise((resolve, reject) => {
      authenticateRequest(credentials)
        .then((result) => {
          const {
            authDTO, userDTO, workspacesDTO, boardDTO
          } = result.data;
          context.commit(AUTH_CONSTANTS.MUTATIONS.AUTH_SUCCESS, { authDTO });
          context.commit(WORKSPACE_CONSTANTS.MUTATIONS.WORKSPACES_SUCCESS, { workspacesDTO });
          context.commit(BOARD_CONSTANTS.MUTATIONS.BOARD_SUCCESS, { boardDTO });
          context.commit(USER_CONSTANTS.MUTATIONS.USER_SUCCESS, userDTO);
          resolve();
        })
        .catch(error => reject(error));
    });
  },

  [AUTH_CONSTANTS.ACTIONS.AUTH_LOGOUT]({ commit }) {
    commit(AUTH_CONSTANTS.MUTATIONS.AUTH_RESET);
    commit(USER_CONSTANTS.MUTATIONS.USER_RESET);
    commit(WORKSPACE_CONSTANTS.MUTATIONS.WORKSPACES_RESET);
    commit(INBOX_CONSTANTS.MUTATIONS.INBOXES_RESET);
    commit(PROJECT_CONSTANTS.MUTATIONS.PROJECTS_RESET);
    commit(STORY_CONSTANTS.MUTATIONS.STORIES_RESET);
    commit(COMMENT_CONSTANTS.MUTATIONS.STORY_COMMENTS_RESET);
    commit(BOARD_CONSTANTS.MUTATIONS.BOARD_RESET);
  },
};
