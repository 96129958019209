import { CONSTANTS as INBOX_CONSTANTS } from '@/store/modules/inbox/inbox.constants';
import { CONSTANTS as WORKSPACE_CONSTANTS, } from '@/store/modules/workspace/workspace.constants';
import { CONSTANTS as USER_CONSTANTS } from '@/store/modules/user/user.constants';
import InboxesApi from '@/api/inboxes.api';

export default {
  [INBOX_CONSTANTS.ACTIONS.INBOX_CREATE](context, emails) {
    const { _id, title } = context.getters[WORKSPACE_CONSTANTS.GETTERS.OWNER_WORKSPACE];
    const inviteUserToWorkspace = { workspaceID: _id, workspaceTitle: title };
    return new Promise((resolve, reject) => {
      InboxesApi.create({ ...emails, metadata: { inviteUserToWorkspace } }, 'inviteUserToWorkspace')
        .then(() => {
          resolve();
        })
        .catch(error => reject(error));
    });
  },

  [INBOX_CONSTANTS.ACTIONS.INBOX_DELETE](context, inbox) {
    return new Promise((resolve, reject) => {
      InboxesApi.delete(inbox._id)
        .then(() => {
          context.dispatch(USER_CONSTANTS.ACTIONS.USER_INBOXES_REQUEST)
            .catch(error => reject(error));
          resolve();
        })
        .catch(error => reject(error));
    });
  },
};
