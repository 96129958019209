import { CONSTANTS as COMMENT_CONSTANTS } from '@/store/modules/comment/comment.constants';

export default {
  [COMMENT_CONSTANTS.GETTERS.CURRENT_STORY_COMMENTS](state) {
    return state[COMMENT_CONSTANTS.STATE.CURRENT_COMMENTS];
  },
  [COMMENT_CONSTANTS.GETTERS.CURRENT_STORY_COMMENTS_BY_ID]: state => id => (
    state[COMMENT_CONSTANTS.STATE.CURRENT_COMMENTS].find(comment => comment._id === id)
  ),
};
