import { CONSTANTS as PROJECT_CONSTANTS, } from '@/store/modules/project/project.constants';
import getters from '@/store/modules/project/project.getters';
import actions from '@/store/modules/project/project.actions';
import mutations from '@/store/modules/project/project.mutations';

const state = {
  [PROJECT_CONSTANTS.STATE.ACTIVE_WORKSPACE_PROJECTS]: [],
};

export default {
  state,
  getters,
  actions,
  mutations
};
