import { CONSTANTS as INBOX_CONSTANTS } from '@/store/modules/inbox/inbox.constants';

export default {
  [INBOX_CONSTANTS.GETTERS.USER_INBOXES](state) {
    return state[INBOX_CONSTANTS.STATE.CURRENT_USER_INBOXES];
  },
  // eslint-disable-next-line max-len
  [INBOX_CONSTANTS.GETTERS.USER_INBOX]: state => id => state[INBOX_CONSTANTS.STATE.CURRENT_USER_INBOXES]
    .find(item => item._id === id),
};
