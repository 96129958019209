const STATE = {
  CURRENT_COMMENTS: 'currentComments'
};
export const GETTERS = {
  CURRENT_STORY_COMMENTS: 'CURRENT_STORY_COMMENTS',
  CURRENT_STORY_COMMENTS_BY_ID: 'CURRENT_STORY_COMMENTS_BY_ID',
};

export const ACTIONS = {
  STORY_COMMENTS_SUCCESS: 'STORY_COMMENTS_SUCCESS',
  STORY_COMMENT_CREATE: 'STORY_COMMENT_CREATE',
  STORY_COMMENT_DELETE: 'STORY_COMMENT_DELETE',
  STORY_COMMENT_UPDATE: 'STORY_COMMENT_UPDATE',
  COMMENT_REQUEST: 'COMMENT_REQUEST',
};

export const MUTATIONS = {
  STORY_COMMENTS_SUCCESS: 'STORY_COMMENTS_SUCCESS',
  STORY_COMMENTS_RESET: 'STORY_COMMENTS_RESET',
};

export const CONSTANTS = {
  STATE,
  GETTERS,
  ACTIONS,
  MUTATIONS,
};
