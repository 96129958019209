import { CONSTANTS as COMMENT_CONSTANTS } from '@/store/modules/comment/comment.constants';

export default {
  [COMMENT_CONSTANTS.MUTATIONS.STORY_COMMENTS_SUCCESS](state, payload) {
    state[COMMENT_CONSTANTS.STATE.CURRENT_COMMENTS] = payload;
  },
  [COMMENT_CONSTANTS.MUTATIONS.STORY_COMMENTS_RESET](state) {
    state[COMMENT_CONSTANTS.STATE.CURRENT_COMMENTS] = [];
  },
};
