import { CONSTANTS as USER_CONSTANTS } from '@/store/modules/user/user.constants';

export default {
  [USER_CONSTANTS.GETTERS.CURRENT_USER](state) {
    return state[USER_CONSTANTS.STATE.CURRENT_USER];
  },

  [USER_CONSTANTS.GETTERS.ALL_USERS_PROFILE_EXCEPT_CURRENT_USER](state) {
    return state[USER_CONSTANTS.STATE.USERS]
      .filter(user => user._id !== state[USER_CONSTANTS.STATE.CURRENT_USER]._id);
  },

  [USER_CONSTANTS.GETTERS.USERS](state) {
    return state[USER_CONSTANTS.STATE.USERS];
  }
};
