import Api from './api';

const resourceBaseUrl = 'projects';

class ProjectsApi extends Api {

}

ProjectsApi.resourceBaseUrl = resourceBaseUrl;

export default ProjectsApi;
