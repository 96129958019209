<template>
  <div>
    <div v-if="userInboxes.length">
      <article v-for="(inbox, index) in userInboxes"
        v-bind:key="index"
        class="media">
        <div class="media-left">
          <div>From:</div>
          <div>
            {{inbox.from}}
          </div>
        </div>
        <div class="media-content">
          <div class="buttons">
            <b-button
              class="is-primary"
              native-type="submit"
              icon-right="user-plus"
              @click="handleAcceptWorkspaceInvite(inbox)"
              >
              Accept Invite
            </b-button>
            <b-button
              native-type="submit"
              @click="handleDeclineWorkspaceInvite(inbox)"
              >
              Decline
            </b-button>
          </div>
        </div>
      </article>
    </div>
    <div v-else style="text-align: center;"><span>You have no more messages.</span></div>
  </div>
</template>

<script>
import { CONSTANTS as WORKSPACE_CONSTANTS, } from '@/store/modules/workspace/workspace.constants';
import { CONSTANTS as INBOX_CONSTANTS } from '@/store/modules/inbox/inbox.constants';

export default {
  name: 'InboxMenu',
  computed: {
    userInboxes() {
      return this.$store.getters[INBOX_CONSTANTS.GETTERS.USER_INBOXES];
    },
  },
  methods: {
    handleAcceptWorkspaceInvite(inbox) {
      // Note: Instead of dispatching one action manipulating two or more resource types,
      // dispatch one action at a time, after another.
      // Each action, dedicated towards a particular resource.
      this.$store
        .dispatch(INBOX_CONSTANTS.ACTIONS.INBOX_DELETE, inbox)
        .then(() => {
          this.$store
            .dispatch(WORKSPACE_CONSTANTS.ACTIONS.WORKSPACE_ACCEPT, { inboxDTO: inbox })
            .catch(error => console.error(error));
        })
        .catch(error => console.error(error));
    },
    handleDeclineWorkspaceInvite(inbox) {
      this.$store
        .dispatch(INBOX_CONSTANTS.ACTIONS.INBOX_DELETE, inbox)
        .catch(error => console.error(error));
    }
  },
};
</script>

<style>

</style>
