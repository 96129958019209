import Api from './api';

const resourceBaseUrl = 'users';

class UsersApi extends Api {

}

UsersApi.resourceBaseUrl = resourceBaseUrl;

export default UsersApi;
