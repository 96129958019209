/* eslint-disable no-shadow */
import { CONSTANTS as WORKSPACE_CONSTANTS, } from '@/store/modules/workspace/workspace.constants';
import getters from '@/store/modules/workspace/workspace.getters';
import actions from '@/store/modules/workspace/workspace.actions';
import mutations from '@/store/modules/workspace/workspace.mutations';
/* Note: good to read so you understand why constans are separated into its own js file
(it is b/c eliminating circular dependencies in your javascript project):
https://stackoverflow.com/questions/51094117/dependency-cycle-detected-import-no-cycle */
// import { CONSTANTS as AUTH_CONSTANTS, } from '@/store/modules/auth/auth.constants';

const state = {
  [WORKSPACE_CONSTANTS.STATE.WORKSPACES]: [],
  [WORKSPACE_CONSTANTS.STATE.ACTIVE_WORKSPACE]: null
};

export default {
  state,
  getters,
  actions,
  mutations
};
