import { CONSTANTS as USER_CONSTANTS } from '@/store/modules/user/user.constants';
import getters from '@/store/modules/user/user.getters';
import actions from '@/store/modules/user/user.actions';
import mutations from '@/store/modules/user/user.mutations';

const state = {
  [USER_CONSTANTS.STATE.CURRENT_USER]: {
    email: null,
    password: null,
    tickets: [],
    fullName: null,
    username: null
  },
  [USER_CONSTANTS.STATE.USERS]: []
};

export default {
  state,
  getters,
  actions,
  mutations
};
