import { CONSTANTS as AUTH_CONSTANTS, } from '@/store/modules/auth/auth.constants';
import getters from '@/store/modules/auth/auth.getters';
import mutations from '@/store/modules/auth/auth.mutations';
import actions from '@/store/modules/auth/auth.actions';

const state = {
  [AUTH_CONSTANTS.STATE.TOKEN]: '',
};

export default {
  state,
  getters,
  actions,
  mutations
};
