const STATE = {
  ACTIVE_WORKSPACE_PROJECTS: 'activeWorkspaceProjects'
};

export const GETTERS = {
  ACTIVE_WORKSPACE_PROJECTS: 'ACTIVE_WORKSPACE_PROJECTS',
  ACTIVE_WORKSPACE_PROJECT: 'ACTIVE_WORKSPACE_PROJECT',
};

export const ACTIONS = {
  PROJECT_CREATE: 'PROJECT_CREATE',
  PROJECT_UPDATE: 'PROJECT_UPDATE',
};

export const MUTATIONS = {
  PROJECTS_SUCCESS: 'PROJECTS_SUCCESS',
  PROJECTS_RESET: 'PROJECTS_RESET',
};

export const CONSTANTS = {
  STATE,
  GETTERS,
  ACTIONS,
  MUTATIONS,
};
