import Api from './api';

const resourceBaseUrl = 'stories';

class StoriesApi extends Api {

}

StoriesApi.resourceBaseUrl = resourceBaseUrl;

export default StoriesApi;
