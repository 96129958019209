const STATE = {
  WORKSPACES: 'workspaces',
  ACTIVE_WORKSPACE: 'activeWorkspace',
};
export const GETTERS = {
  WORKSPACES: 'WORKSPACES',
  WORKSPACE: 'WORKSPACE',
  ACTIVE_WORKSPACE: 'ACTIVE_WORKSPACE',
  OWNER_WORKSPACE: 'OWNER_WORKSPACE',
  activeWorkspaceMembers: 'activeWorkspaceMembers',
};

export const ACTIONS = {
  WORKSPACES_SUCCESS: 'WORKSPACES_SUCCESS',

  SET_ACTIVE_WORKSPACE: 'SET_ACTIVE_WORKSPACE',

  WORKSPACE_ACCEPT: 'WORKSPACE_ACCEPT',

  WORKSPACE_BOARD_REQUEST: 'WORKSPACE_BOARD_REQUEST',

  WORKSPACE_PROJECTS_REQUEST: 'WORKSPACE_PROJECTS_REQUEST',

  WORKSPACE_STORIES_REQUEST: 'WORKSPACE_STORIES_REQUEST',
};

export const MUTATIONS = {
  WORKSPACES_SUCCESS: 'WORKSPACES_SUCCESS',
  WORKSPACES_RESET: 'WORKSPACES_RESET',
  SET_ACTIVE_WORKSPACE: 'SET_ACTIVE_WORKSPACE',
};

export const CONSTANTS = {
  STATE,
  GETTERS,
  ACTIONS,
  MUTATIONS,
};
