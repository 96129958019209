import { CONSTANTS as BOARD_CONSTANTS } from '@/store/modules/board/board.constants';
import { CONSTANTS as STORY_CONSTANTS } from '@/store/modules/story/story.constants';
import BoardsApi from '@/api/boards.api';

export default {
  [BOARD_CONSTANTS.ACTIONS.BOARD_UPDATE]({ commit }, boardData) {
    return new Promise((resolve, reject) => {
      const { boardID, ...boardDataWithoutID } = boardData;
      BoardsApi.update(boardID, { boardDataWithoutID })
        .then(({ data }) => {
          const { boardDTO } = data;
          commit(BOARD_CONSTANTS.MUTATIONS.BOARD_SUCCESS, { boardDTO });
          resolve();
        })
        .catch(error => reject(error));
    });
  },
  [BOARD_CONSTANTS.ACTIONS.BOARD_TRANSITION_STORY_STATUS](
    { commit, getters, dispatch }, boardData
  ) {
    return new Promise((resolve, reject) => {
      const { _id: storyID, status: newStatus, previousValue: oldStatus } = boardData;

      dispatch(STORY_CONSTANTS.ACTIONS.STORY_UPDATE, {
        _id: storyID,
        status: newStatus,
      })
        .then(() => {
          BoardsApi.update(`${getters[BOARD_CONSTANTS.GETTERS.BOARD]._id}/transition-story-status`,
            {
              newStatus,
              oldStatus,
              storyID
            })
            .then(({ data }) => {
              const { boardDTO } = data;
              commit(BOARD_CONSTANTS.MUTATIONS.BOARD_SUCCESS, { boardDTO });
              resolve();
            })
            .catch(error => reject(error));
        })
        .catch(error => console.error(error));
    });
  },
};
