import axios from 'axios';
import { removeEndingCharacter } from '@/utils/utils';

// https://github.com/gothinkster/vue-realworld-example-app/blob/61742206c170db02b04d63c7e9d43807d8c6b902/src/common/api.service.js
// https://stackoverflow.com/questions/39886830/how-do-i-override-inherited-methods-when-using-javascript-es6-es2015-subclassing

class Api {
  static resourceBaseUrl = '';

  static init() {
    axios.defaults.baseURL = '/api/';
  }

  static query(urlPath = '', params) {
    return axios.get(removeEndingCharacter(`${this.resourceBaseUrl}/${urlPath}`, '/'), params);
  }

  static get(urlPath = '') {
    return axios.get(removeEndingCharacter(`${this.resourceBaseUrl}/${urlPath}`, '/'));
  }

  static create(data, urlPath = '') {
    return axios.post(removeEndingCharacter(`${this.resourceBaseUrl}/${urlPath}`, '/'), data);
  }

  static update(urlPath = '', data) {
    return axios.put(removeEndingCharacter(`${this.resourceBaseUrl}/${urlPath}`, '/'), data);
  }

  static delete(urlPath = '') {
    return axios.delete(removeEndingCharacter(`${this.resourceBaseUrl}/${urlPath}`, '/'));
  }
}

export default Api;
