export const GETTERS = {

};

export const ACTIONS = {
  REGISTER_REQUEST: 'REGISTER_REQUEST',
};

export const MUTATIONS = {
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_ERROR: 'REGISTER_ERROR',
};

export const CONSTANTS = {
  GETTERS,
  ACTIONS,
  MUTATIONS,
};
