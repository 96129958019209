import { CONSTANTS as USER_CONSTANTS } from '@/store/modules/user/user.constants';

export default {
  [USER_CONSTANTS.MUTATIONS.USER_SUCCESS](state, payload) {
    state[USER_CONSTANTS.STATE.CURRENT_USER] = { ...payload };
  },
  [USER_CONSTANTS.MUTATIONS.USER_RESET](state) {
    state[USER_CONSTANTS.STATE.CURRENT_USER] = {};
    state[USER_CONSTANTS.STATE.USERS] = [];
  },
  [USER_CONSTANTS.MUTATIONS.USERS_SUCCESS](state, payload) {
    state[USER_CONSTANTS.STATE.USERS] = payload;
  },
};
