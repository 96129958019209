import Vue from 'vue';
import VueRouter from 'vue-router';
import { CONSTANTS as WORKSPACE_CONSTANTS, } from '@/store/modules/workspace/workspace.constants';
import { CONSTANTS as AUTH_CONSTANTS, } from '@/store/modules/auth/auth.constants';
import Home from '../views/Home.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresVisitor: true,
      title: 'Twork Home'
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      requiresAuth: true,
      title: 'Twork About'
    }
  },
  {
    path: '/:workspaceTitle/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      title: 'Twork Dashboard'
    }
  },
  {
    path: '/:workspaceTitle/stories',
    name: 'stories',
    component: () => import(/* webpackChunkName: "stories" */ '../views/Stories/Stories.vue'),
    meta: {
      requiresAuth: true,
      title: 'Twork Stories'
    }
  },
  {
    path: '/:workspaceTitle/stories/:_id',
    name: 'story',
    component: () => import(/* webpackChunkName: "stories" */ '../views/Story.vue'),
    meta: {
      requiresAuth: true,
      title: 'Twork Story'
    }
  },
  // {
  //   path: '/epics',
  //   name: 'epics',
  //   component: () => import(/* webpackChunkName: "epics" */ '../views/Epics.vue'),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/:workspaceTitle/projects',
    name: 'projects',
    component: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
    meta: {
      requiresAuth: true,
      title: 'Twork Projects'
    }
  },
  /* e.g. '/projects/1'
     somehow if I remove this comment and the path is '/projects/:_id', I get this build-time error:
     Module Error (from ./node_modules/eslint-loader/index.js):
     error: Unable to resolve path to module '../views/Project.vue'
     (import/no-unresolved) at src\router\index.js:63:63
     Why?
     Solution: Can try restarting the vue webpack server.
  */
  {
    path: '/:workspaceTitle/projects/:_id',
    name: 'project',
    component: () => import(/* webpackChunkName: "project" */ '../views/Project.vue'),
    meta: {
      requiresAuth: true,
      title: 'Twork Project'

    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      requiresVisitor: true,
      title: 'Twork Login'
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue'),
    meta: {
      requiresVisitor: true,
      title: 'Twork Sign Up'
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue'),
    meta: {
      requiresAuth: true,
      title: 'Twork Logout'
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: {
      requiresAuth: true,
      title: 'Twork Settings'
    }
  },
  {
    path: '*',
    name: 'pageNotFound',
    component: () => import(/* webpackChunkName: "pageNotFound" */ '../views/PageNotFound.vue'),
    meta: {
      title: 'Twork Page Not Found'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = (to.meta && to.meta.title) || 'Twork';

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.getters[AUTH_CONSTANTS.GETTERS.IS_AUTHENTICATED]) {
      const activeWorkspace = store
        .getters[WORKSPACE_CONSTANTS.GETTERS.WORKSPACE](to.params.workspaceTitle);
      if (activeWorkspace) {
        // TODO: Should be dispatchiing an action instead
        store.dispatch(WORKSPACE_CONSTANTS.ACTIONS.SET_ACTIVE_WORKSPACE, activeWorkspace);
        next();
      } else if (
        to.name === 'logout'
        || to.name === 'settings'
      ) {
        next();
      } else {
        next({
          name: 'pageNotFound',
        });
      }
    } else {
      next({
        name: 'login',
      });
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters[AUTH_CONSTANTS.GETTERS.IS_AUTHENTICATED]) {
      next({
        name: 'pageNotFound',
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
