export function getInitials(name) {
  const parts = name.split(' ');
  let initials = '';
  for (let i = 0; i < parts.length; i += 1) {
    if (parts[i].length > 0 && parts[i] !== '') {
      initials += parts[i][0];
    }
  }
  return initials;
}

export function replaceHashWithUnderscore(hexColor) {
  return hexColor.replace('#', '_');
}

export function unCamelCase(camelCasedString) {
  return camelCasedString
    // insert a space before all caps
    .replace(/([A-Z])/g, ' $1')
  // uppercase the first character
    .replace(/^./, str => str.toUpperCase());
}

export function removeEndingCharacter(
  str = '', character = ''
) {
  let formatteredStr = String(str);

  if (character && str.endsWith(character)) {
    formatteredStr = str.substring(0, str.length - 1);
  }

  return formatteredStr;
}

// https://github.com/Abazhenov/express-async-handler/blob/master/index.js
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/catch
export function asyncHandler(fn, errorHandler) {
  return (...args) => {
    const fnReturn = fn(...args);
    return Promise.resolve(fnReturn).catch(errorHandler);
  };
}
