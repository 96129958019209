import Api from './api';

const resourceBaseUrl = 'workspaces';

class WorkspacesApi extends Api {

}

WorkspacesApi.resourceBaseUrl = resourceBaseUrl;

export default WorkspacesApi;
