import { CONSTANTS as AUTH_CONSTANTS, } from '@/store/modules/auth/auth.constants';

export default {
  [AUTH_CONSTANTS.MUTATIONS.AUTH_SUCCESS](state, { authDTO }) {
    // https://stackoverflow.com/questions/58339742/vuex-mutations-replacing-the-whole-state
    // https://forum.vuejs.org/t/vuex-replace-state-with-api-initial-state/3390
    // https://gist.github.com/DawidMyslak/2b046cca5959427e8fb5c1da45ef7748
    Object.assign(state, authDTO);
  },
  [AUTH_CONSTANTS.MUTATIONS.AUTH_RESET](state) {
    state[AUTH_CONSTANTS.STATE.TOKEN] = '';
    state[AUTH_CONSTANTS.STATE.CURRENT_USER] = '';
  },
};
