import { CONSTANTS as STORY_CONSTANTS } from '@/store/modules/story/story.constants';

export default {
  [STORY_CONSTANTS.MUTATIONS.STORIES_SUCCESS](state, payload) {
  // probably should be called activeWorkspaceStories
    state[STORY_CONSTANTS.STATE.CURRENT_USER_STORIES] = payload;
  },
  [STORY_CONSTANTS.MUTATIONS.STORIES_RESET](state) {
    state[STORY_CONSTANTS.STATE.CURRENT_USER_STORIES] = [];
  },
};
