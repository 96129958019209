const STATE = {
  TOKEN: 'token',
};
export const GETTERS = {
  IS_AUTHENTICATED: 'IS_AUTHENTICATED',
};

export const ACTIONS = {
  AUTH_REQUEST: 'AUTH_REQUEST',
  AUTH_LOGOUT: 'AUTH_LOGOUT',
};

export const MUTATIONS = {
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  AUTH_RESET: 'AUTH_RESET',
};

export const CONSTANTS = {
  STATE,
  GETTERS,
  ACTIONS,
  MUTATIONS,
};
