const STATE = {
  CURRENT_USER_INBOXES: 'currentUserInboxes'
};

export const GETTERS = {
  // Note: Probably a better naming would be 'USER_INBOX_MESSAGES'.
  // Then 'USER_BOX' would represent all messages in a user's inbox,
  // instead, here, 'USER_INBOX' representing a user's single message
  USER_INBOXES: 'USER_INBOXES',
  USER_INBOX: 'USER_INBOX',
};

export const ACTIONS = {
  INBOX_DELETE: 'INBOX_DELETE',
  INBOX_CREATE: 'INBOX_CREATE',
};

export const MUTATIONS = {
  INBOXES_SUCCESS: 'INBOXES_SUCCESS',
  INBOXES_RESET: 'INBOXES_RESET',
};

export const CONSTANTS = {
  STATE,
  GETTERS,
  ACTIONS,
  MUTATIONS,
};
