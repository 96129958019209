import { CONSTANTS as INBOX_CONSTANTS } from '@/store/modules/inbox/inbox.constants';
import getters from '@/store/modules/inbox/inbox.getters';
import actions from '@/store/modules/inbox/inbox.actions';
import mutations from '@/store/modules/inbox/inbox.mutations';

const state = {
  [INBOX_CONSTANTS.STATE.CURRENT_USER_INBOXES]: [],
};

export default {
  state,
  getters,
  actions,
  mutations
};
