import { CONSTANTS as STORY_CONSTANTS } from '@/store/modules/story/story.constants';

export default {
  [STORY_CONSTANTS.GETTERS.USER_STORIES](state) {
    return state[STORY_CONSTANTS.STATE.CURRENT_USER_STORIES];
  },
  // eslint-disable-next-line max-len
  [STORY_CONSTANTS.GETTERS.USER_STORY]: state => id => state[STORY_CONSTANTS.STATE.CURRENT_USER_STORIES]
    .find(item => item._id === id),
  [STORY_CONSTANTS.GETTERS.STORY_TYPES]: () => ['Feature', 'Bug', 'Task'],
  [STORY_CONSTANTS.GETTERS.STORY_TYPES_WITH_ICON]: () => [
    {
      name: 'Feature',
      icon: 'magic',
      color: '#FCC400',
    },
    {
      name: 'Bug',
      icon: 'bug',
      color: '#9F0500',
    },
    {
      name: 'Task',
      icon: 'tools',
      color: '#808080',
    },
  ],
  [STORY_CONSTANTS.GETTERS.STORY_PRIORITIES]: () => ['Trivial', 'Medium', 'Major', 'Critical', 'Blocker'],
  [STORY_CONSTANTS.GETTERS.STORY_PRIORITIES_WITH_ICON]: () => [
    {
      name: 'Trivial',
      icon: 'arrow-down',
      color: '#808080',
    },
    {
      name: 'Medium',
      icon: 'equals',
      color: '#68BC00',
    },
    {
      name: 'Major',
      icon: 'arrow-up',
      color: '#E27300',
    },
    {
      name: 'Critical',
      icon: 'angle-double-up',
      color: '#F44E3B',
    },
    {
      name: 'Blocker',
      icon: 'ban',
      color: '#9F0500',
    },
  ],
};
