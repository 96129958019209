import { find, filter } from 'lodash';
import { CONSTANTS as WORKSPACE_CONSTANTS, } from '@/store/modules/workspace/workspace.constants';
import { CONSTANTS as USER_CONSTANTS } from '@/store/modules/user/user.constants';

export default {
  [WORKSPACE_CONSTANTS.GETTERS.WORKSPACES](state) {
    return state[WORKSPACE_CONSTANTS.STATE.WORKSPACES];
  },
  [WORKSPACE_CONSTANTS.GETTERS.WORKSPACE](state) {
    return workspaceTitle => state[WORKSPACE_CONSTANTS.STATE.WORKSPACES]
      .find(workspace => workspace.title === workspaceTitle);
  },
  [WORKSPACE_CONSTANTS.GETTERS.ACTIVE_WORKSPACE](state) {
    return state[WORKSPACE_CONSTANTS.STATE.ACTIVE_WORKSPACE];
  },
  [WORKSPACE_CONSTANTS.GETTERS.OWNER_WORKSPACE](state, getters) {
    return find(
      state[WORKSPACE_CONSTANTS.STATE.WORKSPACES],
      workspace => workspace.owner === getters[USER_CONSTANTS.GETTERS.CURRENT_USER]._id
    );
  },
  activeWorkspaceMembers(state, getters) {
    return filter(
      getters[USER_CONSTANTS.GETTERS.USERS],
      user => state[WORKSPACE_CONSTANTS.STATE.ACTIVE_WORKSPACE].members.includes(user._id)
    );
  }
};
