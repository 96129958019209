import { CONSTANTS as COMMENT_CONSTANTS } from '@/store/modules/comment/comment.constants';
import { CONSTANTS as WORKSPACE_CONSTANTS, } from '@/store/modules/workspace/workspace.constants';
import CommentsApi from '@/api/comments.api';

export default {
  [COMMENT_CONSTANTS.ACTIONS.STORY_COMMENT_CREATE](context, commentData) {
    return new Promise((resolve, reject) => {
      const workspace = context.getters[WORKSPACE_CONSTANTS.GETTERS.ACTIVE_WORKSPACE]._id;

      CommentsApi.create({ ...commentData, workspace })
        .then(() => {
          resolve();
        })
        .catch(error => reject(error));
    });
  },

  [COMMENT_CONSTANTS.ACTIONS.STORY_COMMENT_DELETE](context, commentId) {
    return new Promise((resolve, reject) => {
      CommentsApi.delete(commentId)
        .then(() => {
          resolve();
        })
        .catch(error => reject(error));
    });
  },

  [COMMENT_CONSTANTS.ACTIONS.COMMENT_REQUEST](context, commentID) {
    return new Promise((resolve, reject) => {
      CommentsApi.get(commentID)
        .then(({ data }) => {
          context.commit(COMMENT_CONSTANTS.MUTATIONS.COMMENT_SUCCESS, data);
          resolve();
        })
        .catch(error => reject(error));
    });
  },

  [COMMENT_CONSTANTS.ACTIONS.STORY_COMMENT_UPDATE](context, commentData) {
    return new Promise((resolve, reject) => {
      const { commentId, ...commentDataWithoutId } = commentData;
      CommentsApi.update(commentId, { commentDataWithoutId })
        .then(() => {
          resolve();
        })
        .catch(error => reject(error));
    });
  },
};
