/* eslint-disable no-shadow */
import { CONSTANTS as BOARD_CONSTANTS } from '@/store/modules/board/board.constants';
import getters from '@/store/modules/board/board.getters';
import actions from '@/store/modules/board/board.actions';
import mutations from '@/store/modules/board/board.mutations';

const state = {
  [BOARD_CONSTANTS.STATE.BOARD]: null,
};

export default {
  state,
  getters,
  actions,
  mutations
};
